import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from "../context/AuthContext";

const BoBanner2 = () => {
    const auth = useContext(AuthContext);
    const [data, setData] = useState({});

    useEffect(() => {
        try{
            // get_order_list_by_shop
            const listOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_building_owner_detail/${auth.userId}`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                  }
            })
            const datas = await response.json();
            setData(datas.user);
            console.log(datas);
            }
            listOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])
  return (
    <>
        <header className='shop-owner-custo'>
            <div className='bo-top-banner'>
                <img src='/img/buildingowner/banner.png' alt='logo'/>
            </div>
            <div className='top-shop-name'>
                <div className='shop-name-bo'>
                    <img src='/img/buildingowner/banner-logo.png' alt='logo'/>
                    <h3>{data.name && data.name}</h3>
                </div>
                {/* <div className='serch-setting'>
                    <img src='/img/search.png' alt='search'/>
                    <img src='/img/buildingowner/settings.png' alt='search'/>
                </div> */}
            </div>
            {/* <section className='personal-shop-detail'>
                <div className='aside-header'>
                    <div className='shop-name-detail'>
                        <div className=''>
                            <img src='./img/buildingowner/logo7.png' alt='logo'/>
                        </div>
                        <div className='starbucks'>
                            <h4>Starbucks</h4>
                            <p>Today 10 Delivered orders. </p>
                        </div>
                    </div>
                    <a href='#'><img src='./img/buildingowner/icon.png' alt='icon'/></a>
                </div>
            </section> */}
            
        </header>

    </>
  )
}

export default BoBanner2