import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from "../context/AuthContext";

const BuildingBanner = () => {
    const auth = useContext(AuthContext);
    const [data, setData] = useState({});

    useEffect(() => {
        try{
            // get_order_list_by_shop
            const listOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_building_owner_detail/${auth.userId}`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                  }
            })
            const datas = await response.json();
            setData(datas.user);
            console.log(datas);
            }
            listOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])

  return (
    <>

    <header>
       
        <div  className='bo-header'>
            <div className='bo-banner'>
                <img src='/img/buildingowner/banner.png' alt='banner'/>
            </div>

            <section className='bo-wrap-sec'>
                <div className='bo-banner-text'>
                    <div className='bo-banner-logo'>
                        <img src='/img/buildingowner/banner-logo.png' alt="shop logo"/>
                        <img className='cam-icon' src='./img/cameraicon.png' alt="camera icon"/>
                    </div>
                    <div className='shop-banner'>
                        <h2>{data.name && data.name}</h2>
                        <p>{data.description  && data.description}</p>
                    </div>
                </div>
            </section>
            {/* <div className='bo-relative'>
                <div className='bo-input-sett'>
                    <input type='search' placeholder='Search here' className='bo-search'/>
                    <img src='./img/buildingowner/settings.png' alt='setting icon'/>
                </div>
            </div> */}
        </div>
    </header>

    </>
  )
}

export default BuildingBanner