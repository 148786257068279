import React, {useContext} from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import './responsive.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/auth-hook";

const MainApp = () => {
  const auth = useContext(AuthContext);
  const { isLoggedIn, token, login, logout, userId } = useAuth();
  console.log('index auth');
  console.log(isLoggedIn);
  return (
    <AuthContext.Provider value={{
      isLoggedIn: isLoggedIn, 
      token: token,
      userId: userId,
      login: login, 
      logout: logout 
    }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   
    {/* <App /> */}
    <MainApp />
  </React.StrictMode>
);