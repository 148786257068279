import React from 'react'
import BoBanner2 from '../Components/BoBanner2';
import ShoperDetail2 from '../Components/ShoperDetail2'


const BuildingOwnerPage3 = () => {
  return (
    <>
        <div className='bo-wrapper-2'>
            <BoBanner2/>
            <ShoperDetail2/>
        </div>
    </>
  )
}

export default BuildingOwnerPage3
