import React, { useState, useEffect, useContext } from 'react';
import liff from '@line/liff';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const AuthHandler = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  console.log('Authhandle auth');
  console.log(auth);
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: '2003587592-X3xLnzRD' }); // Replace with your LIFF ID

        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const userProfile = await liff.getProfile();
          console.log('userProfile');
          console.log(userProfile);
          try {
            const response = await fetch('https://ybot-backend.salvator.app/api/building-owner-login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ line_id: userProfile.userId }),
            });

            if (response.status === 401) {
              navigate('/register', { state: { lineId: userProfile.userId, profilePic: userProfile.pictureUrl } });
            } else if (!response.ok) {
              throw new Error('Failed to login');
            } else {
              const data = await response.json();
              auth.login(userProfile.pictureUrl, data.userId, data.token);
              navigate('/'); // Redirect to home or any other route on successful login
            }
          } catch (error) {
            console.error('Login failed', error);
          }
        }
      } catch (error) {
        console.error('LIFF initialization failed', error);
      }
      finally {
        setLoading(false); // Ensure loading is stopped regardless of the outcome
      }
    };

    // Check if already logged in
    if (!auth.isLoggedIn) {
      initializeLiff();
    }
    else {
        setLoading(false); // Stop loading if already logged in
    }


     // Function to call login API when the homepage loads
    //  const login = async () => {
    //   try {
    //     const response = await fetch('https://ybot-backend.salvator.app/api/building-owner-login', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({ line_id: 'U3fdc13db353b8d2e3a0710dfab5e6135' }),
    //     });

    //     if (response.status === 401) {
    //         navigate('/register', { state: { lineId: 'U3fdc13db353b8d2e3a0710dfab5e6135', profilePic: '' } });
    //       } else if (!response.ok) {
    //         throw new Error('Failed to login');
    //       } else {
    //         const data = await response.json();
    //         auth.login(data.buildingName, '', data.userId, data.token);
    //         navigate('/'); // Redirect to home or any other route on successful login
    //       }

    //     // if (!response.ok) {
    //     //   throw new Error('Failed to login ');
    //     // }
    //     // const data = await response.json();
    //     //  console.log(data);
    //     // // console.log(response.token);
    //     // // Call updateUser function to set the userId
    //     // // auth.login(data.userId, data.token);
    //     // auth.login('', data.userId, data.token);
    //     // If login successful, set isLoggedIn to true
    //     // setIsLoggedIn(true);
    //     setLoading(false); 
    //   } catch (error) {
    //     // If login fails, set error message
    //     // setErrorMessage('Failed to login. Please try again.');
    //   }
    //   finally {
    //         setLoading(false); // Ensure loading is stopped regardless of the outcome
    //       }
    // };

    //   if (!auth.isLoggedIn) {
    //   login();
    //   }
      // else {
      //     setLoading(false); // Stop loading if already logged in
      // }

    // login(); // Call the login function when the component mounts

  }, [auth, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return null;
};

export default AuthHandler;
