
import { useContext } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes ,
  Navigate
} from 'react-router-dom';
import  BuildingOwnerPage1 from './Pages/BuildingOwnerPage1';
import BuildingOwnerPage2 from './Pages/BuildingOwnerPage2';
import BuildingOwnerPage3 from './Pages/BuildingOwnerPage3'
import Login from "./Pages/Login";
import { AuthContext } from "./context/AuthContext";
import AuthHandler from './AuthHandler';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<div className="App"><BuildingOwnerPage1/></div>} />
//         <Route path="/page2" element={<div className="App"><BuildingOwnerPage2/></div>} />
//         <Route path="/page3" element={<div className="App"><BuildingOwnerPage3/></div>} />
        
//       </Routes>
//     </Router>
  
//   );
// }

// export default App;

const App = () => {
  const auth = useContext(AuthContext);
  console.log(auth.isLoggedIn);
 return (
   <Routes>
     {auth.isLoggedIn ? (
       <>
          <Route path="/" element={<div className="App"><BuildingOwnerPage1/></div>} />
          <Route path="/order-list/:shopId" element={<div className="App"><BuildingOwnerPage2/></div>} />
          <Route path="/order-detail/:orderId" element={<div className="App"><BuildingOwnerPage3/></div>} />
          <Route path="/register" element={<Login />} />
       </>
     ) : (
       <>
           <Route path="/register" element={<Login />} />
           {/* <Route path="*" element={<Navigate to="/register" />} /> */}
       </>
     )}
   </Routes>
 );
};

const MainApp = () => {
 return (
   <>
     <AuthHandler />
     <App />
   </>
 );
};

export default MainApp;
