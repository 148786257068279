import React, {useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

let Shoporder = [
    {
        logo:"./img/buildingowner/logo1.png",
        name:"Starbucks ",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo2.png",
        name:"Yayoi Restaurant",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo3.png",
        name:"Santa Fe' Steak",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo4.png",
        name:"Greyhound Cafe",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo5.png",
        name:"Domino's Pizza",
        totalOrder:"Today 10 Delivered orders."
    },
    {
        logo:"./img/buildingowner/logo6.png",
        name:"sizzler",
        totalOrder:"Today 10 Delivered orders."
    }

]

const ShopList = () => {
    const auth = useContext(AuthContext);
    let [shopData, setShopData] = useState([]);
    let shopId = '660a620ff16175693097b78f';

    useEffect(() => {
        try{
            // get_merchant_initial_order
            const listShop = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_shop_list/${shopId}`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                  }
            })
            const datas = await response.json();
            setShopData(datas.shopList);
            console.log(datas);
            }
            listShop()
        } catch(err) {
            console.log(err);
        }
    }, [])

  return (
    <>

    <h3 className='sh-list'>Shop List :</h3>

        {

            shopData.map((shop)=>{
                    console.log(shop);
                    const{logo, merchantName, merchantId, totalOrders}=shop
                    return(
                        <div className='shop-relative'>
                            <div className='shop-sell-pro'>
                                <div>
                                <Link to={`/order-list/${merchantId}`}>
                                    <img src={logo} alt='shop'/>
                                </Link>
                                </div>
                                <div className='classic-shop'>
                                    <h3>{merchantName}</h3>
                                    <p>{totalOrders} Delivered orders</p>
                                </div>
                            </div>
                            <Link to={`/order-list/${merchantId}`}>
                            <img className='circle-icon' src='./img/circle-right.png' alt='arrow icon'/>
                            </Link>
                        </div>
                    )

                })

             //Shoporder.map((shop)=>{
            //     console.log(shop);
            //     const{logo,name,totalOrder}=shop
            //     return(
            //         <div className='shop-relative'>
            //             <div className='shop-sell-pro'>
            //                 <div>
            //                     <img src={logo} alt='shop'/>
            //                 </div>
            //                 <div className='classic-shop'>
            //                     <h3>{name}</h3>
            //                     <p>{totalOrder}</p>
            //                 </div>
            //             </div>
            //             <Link to='/page2'>
            //             <img className='circle-icon' src='./img/circle-right.png' alt='arrow icon'/>
            //             </Link>
            //         </div>
            //     )

            // }) 
            
        }

    </>
  )
}

export default ShopList