import React, {useState, useEffect, useContext} from 'react'
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const shoper = [
  {
    order:5,
    picture:"/img/buildingowner/customer1.png",
    room:"Room 101",
    name:"Sekar"
  },
  {
    order:2,
    picture:"/img/buildingowner/customer2.png",
    room:"Room 102",
    name:"Sekar"
  },
  {
    order:1,
    picture:"/img/buildingowner/customer3.png",
    room:"Room 103",
    name:"Sekar"
  },
  {
    order:2,
    picture:"/img/buildingowner/customer4.png",
    room:"Room 101",
    name:"Sekar"
  },
//   {
//     order:4,
//     picture:"./img/buildingowner/customer1.png",
//     room:"Room 102",
//     name:"Sekar"
//   },
//   {
//     order:1,
//     picture:"./img/buildingowner/customer1.png",
//     room:"Room 106",
//     name:"Sekar"
//   }
]

const ShoperDetail2 = () => {
  const auth = useContext(AuthContext);
  let [orderData, setOrderData] = useState([]);
  let [shopId, setShopId] = useState('');
  let [items, setItems] = useState([]);
  let orderId = useParams().orderId;
  // get_order_detail

//   useEffect(() => {
//     try{
//         // get_order_list_by_shop
//         const listOrder = async () => {
//         const response =  await fetch(`https://ybot-backend.salvator.app/api/get_order_detail/${orderId}`)
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const contentType = response.headers.get('content-type');
//         if (contentType && contentType.includes('application/json')) {
//             const datas = await response.json();
//             console.log(datas);
//             setItems(datas.merchantOrder[0].items);
//             setOrderData(datas.merchantOrder[0]);
//             setShopId(datas.merchantOrder[0].shopId);
//         } else {
//             throw new Error('Received content is not JSON');
//         }

//         // const datas = await response.json();
//         // console.log(datas);
//         // setItems(datas.merchantOrder[0].items);
//         // setOrderData(datas.merchantOrder[0]);
//         // setShopId(datas.merchantOrder[0].shopId);
        
//         }
//         listOrder();
//     } catch(err) {
//         console.log(err);
//     }
// }, [])

    useEffect(() => {
      const listOrder = async () => {
          try {
              const response = await fetch(`https://ybot-backend.salvator.app/api/get_order_detail/${orderId}`, {
                headers: {
                  'Authorization': `Bearer ${auth.token}`
                }
              });
              
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }

              const contentType = response.headers.get('content-type');
              if (contentType && contentType.includes('application/json')) {
                  const datas = await response.json();
                  console.log(datas);
                  setItems(datas.merchantOrder[0].items);
                  setOrderData(datas.merchantOrder[0]);
                  setShopId(datas.merchantOrder[0].shopId);
              } else {
                  throw new Error('Received content is not JSON');
              }

          } catch (err) {
              console.error('Fetch error:', err);
          }
      };
      listOrder();
    }, [orderId]);


  return (
    <>
    <section className='sh-section'>
          <section className='personal-shop-detail'>
                <div className='aside-header'>
                    <div className='shop-name-detail'>
                        <div className=''>
                          <Link to={`/order-list/${shopId}`}>
                            <img src='/img/buildingowner/logo6.png' alt='logo'/>
                          </Link>
                        </div>
                        <div className='starbucks'>
                            <h4>{orderData.merchantName}</h4>
                            <p>{orderData.totalItemsCount} Delivered orders. </p>
                        </div>
                    </div>
                    {/* <a href='#'> */}
                      <img src='/img/buildingowner/icon.png' alt='icon'/>
                      {/* </a> */}
                </div>
            </section>

          {/* <div className='date-wrap-div'>
              <div className='date-div'>
                <p className='filter-icon'><img id="f-icon" src='/img/buildingowner/filter_list.png' alt='filter logo'/> <span className='filter-text'>Filter</span></p>
                <p>
                  <input type='date'/>
                </p>
              </div>
            </div> */}
    
            <div className='wrapper-test'>
              <div className='grid-person'>
                  <div className='shoper-flex-list'>
                      <p className='filter-item'>{orderData.totalItemsCount}<img src='/img/buildingowner/route.png' alt='icon'/></p>
                      <div className='shoper-test'>
                          <img src='/img/buildingowner/logo6.png' alt="user"/>
                          <div className='shoper-list'>
                            <p className='shoper-room'>{orderData.location}</p>
                            <p className='shoper-name'>{orderData.username}</p>
                          </div>
                    </div>
                </div>
                {/* <p className='detatil-grid'>Details <img src='/img/buildingowner/arrow.png' alt='icon'/></p> */}
              
                </div>

                <div className='amt-line'>
                    <div className='amt-abosulte'>

                    {
         items && items.map((person, index)=>{
          //console.log(person);
          //let{order,picture,room,name}= person
          // let{orderId, items, itemCount,profilePic,location,username}= person

          return(
            <>
                    <div className='amt-felx-main'>
                        <div className='am-div'>
                            <p className='amt-sub-text'>{index + 1} <img src='/img/buildingowner/route.png' alt='icon'/></p>
                            <p className='amt-text'>{person.dishName}</p>
                        </div>
                        <p className='amt-fee'>Price: ฿{person.price}</p>
                    </div>
                    </>
                  )
                  
            
                })

            }

                </div>
                </div>
                
            </div>
        
    </section>
 
    </>
  )
}

export default ShoperDetail2