import React, { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [otp, setOtp] = useState(new Array(6).fill(''));
    let [submit, setSubmit] = useState(false);
    const inputs = useRef([]);

    const location = useLocation();
    // const { state } = location;
    const state = location.state || {};
    const lineId = state.lineId || '';
    const profilePic = state.profilePic || '';
    const buildingName = state.buildingName || '';
    console.log(state);
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;
    
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    
        // Focus on next input
        if (element.nextSibling) {
          element.nextSibling.focus();
        }
      };
    
      const handleBackspace = (element, index) => {
        if (element.value === '') {
          if (element.previousSibling) {
            element.previousSibling.focus();
          }
        }
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
      };

      let handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
      }

    useEffect(() => {
        if(submit){
        const login = async () => {
            console.log(otp);
            let invitation_code = otp.join('');
            try {
              const response = await fetch('https://ybot-backend.salvator.app/api/register-building-owner', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ invitation_code: invitation_code, line_id: lineId }),
              });
      
              if (!response.ok) {
                throw new Error('Failed to login ');
              }
              const data = await response.json();
               console.log(data);
              // console.log(response.token);
              // Call updateUser function to set the userId
              auth.login(buildingName,profilePic, data.userId, data.token);
              // If login successful, set isLoggedIn to true
              setIsLoggedIn(true);
              setSubmit(false);
              navigate('/');
            } catch (error) {
              // If login fails, set error message
              setErrorMessage('Failed to login. Please try again.');
            }
          };
      
          login(); // Call the login function when the component mounts
        }
    }, [submit])

  return (
    <>
        <section className='login-sec'>
            <div className='ybot-login-image'>
                <img src='/img/ybot-logo.png' alt='ybot'/>
            </div>
            <div className='invitation-text'>
                <h2>Invitation Code</h2>
                <p>If you don’t have invitation code please contact juristic</p>
            </div>
            <form className='pincode' onSubmit={handleSubmit}>
                <div className='pincode-flex otp-input'>
                    {/* <input type='number' />
                    <input type='number'/>
                    <input type='number'/>
                    <input type='number'/>
                    <input type='number'/> */}
                    {otp.map((data, index) => (
                        <input
                        className="otp-field"
                        type="number"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={e => handleChange(e.target, index)}
                        onKeyDown={e => e.key === 'Backspace' && handleBackspace(e.target, index)}
                        ref={el => (inputs.current[index] = el)}
                        required
                        />
                    ))}
                </div>
                <button className='hytech-submit'>Submit</button>
             </form>
             <div className='add-new-banner'>
                <img src='/img/invite-ad.jpg' alt='add banner'/>
             </div>
             <div className='add-new-banner'>
                <img src='/img/inviteybot.webp' alt='add banner'/>
             </div>


        </section>
    </>
  )
}

export default Login
