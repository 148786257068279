import React, {useState, useEffect, useContext} from 'react'
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";

const shoper = [
  {
    order:5,
    picture:"./img/buildingowner/customer1.png",
    room:"Room 101",
    name:"Sekar"
  },
  {
    order:2,
    picture:"./img/buildingowner/customer2.png",
    room:"Room 102",
    name:"Sekar"
  },
  {
    order:1,
    picture:"./img/buildingowner/customer3.png",
    room:"Room 103",
    name:"Sekar"
  },
  {
    order:2,
    picture:"./img/buildingowner/customer4.png",
    room:"Room 101",
    name:"Sekar"
  },
  {
    order:4,
    picture:"./img/buildingowner/customer1.png",
    room:"Room 102",
    name:"Sekar"
  },
  {
    order:1,
    picture:"./img/buildingowner/customer1.png",
    room:"Room 106",
    name:"Sekar"
  }
]

const ShoperDetail = () => {
  const auth = useContext(AuthContext);
  const [date, setDate] = useState('');
  const [orders, setOrders] = useState([]);
  let [orderData, setOrderData] = useState([]);
  let [totalOrders, setTotalOrders] = useState();
  let [selectedOrder, setSelectedOrder] = useState();
  let [reset, setReset] = useState(false);
  let [shopName, setShopName] = useState();
    // let shopId = '660a620ff16175693097b78f';
    let shopId = useParams().shopId;

    useEffect(() => {
        try{
            // get_order_list_by_shop
            const listOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_order_list_by_shop/${shopId}`, {
              headers: {
                'Authorization': `Bearer ${auth.token}`
              }
            })
            const datas = await response.json();
            setOrderData(datas.orderList[0].orders);
            setTotalOrders(datas.orderList[0].totalOrders)
            setShopName(datas.orderList[0].merchantName)
            console.log(datas);
            }
            listOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])

    let handleClick = (orderId) => {
      // alert('hello')
      setSelectedOrder(orderId);
      console.log(orderId);
    }

    const fetchOrders = async (selectedDate) => {
      try {
        setOrderData([]);
        setTotalOrders('')
        setShopName('');
        const response = await fetch(`https://ybot-backend.salvator.app/api/get_orders_by_filter/${selectedDate}/${shopId}`, {
          headers: {
            'Authorization': `Bearer ${auth.token}`
          }
        });
        const data = await response.json();
        setOrderData(data ? data.orderList[0].orders : []);
        setTotalOrders(data ? data.orderList[0].totalOrders : '')
        setShopName(data ? data.orderList[0].merchantName : '')
        // setOrders(data && data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    
    const handleDateChange = (event) => {
      const selectedDate = event.target.value;
      setDate(selectedDate);
      fetchOrders(selectedDate);
    };

    useEffect(() => {
      try{
          // get_order_list_by_shop
          const listOrder = async () => {
          const response =  await fetch(`https://ybot-backend.salvator.app/api/get_order_list_by_shop/${shopId}`, {
            headers: {
              'Authorization': `Bearer ${auth.token}`
            }
          })
          const datas = await response.json();
          setOrderData(datas.orderList[0].orders);
          setTotalOrders(datas.orderList[0].totalOrders)
          setShopName(datas.orderList[0].merchantName)
          console.log(datas);
          }
          if(reset){
            listOrder()
          }
         
      } catch(err) {
          console.log(err);
      }
  }, [reset])
  
    const handleReset = async () => {
      setReset(true);
    };

  return (
    <>
    <section className='sh-section'>
          <section className='personal-shop-detail'>
                <div className='aside-header'>
                    <div className='shop-name-detail'>
                    <Link to={'/'}>
                        <div className=''>
                            <img src='/img/buildingowner/logo6.png' alt='logo'/>
                        </div>
                        </Link>
                        <div className='starbucks'>
                            <h4>{shopName}</h4>
                            <p>{totalOrders} Delivered orders. </p>
                        </div>
                      
                    </div>
                    {/* <a href='#'><img src='/img/buildingowner/icon.png' alt='icon'/></a> */}
                </div>
            </section>

          <div className='date-wrap-div'>
              <div className='date-div'>
                <p className='filter-icon'><img id="f-icon" src='/img/buildingowner/filter_list.png' alt='filter logo'/> <span className='filter-text'>Filter</span></p>
                <p>
                  <input type='date' value={date} onChange={handleDateChange}/> 
                  {/* <Link to={`/order-list/${shopId}`}> */}
                  <button class="btn btn-secondary" onClick={handleReset}>Reset</button>
                  {/* </Link> */}
                </p>
              </div>
            </div>
    {

        orderData.map((person)=>{
          console.log(person);
          let{orderId, items, itemCount,profilePic,location,username}= person

          return(
            <div className='wrapper-test'>
              <div className='grid-person'>
                  <div className='shoper-flex-list'>
                      <p className='filter-item'>{itemCount}<img src='/img/buildingowner/route.png' alt='icon'/></p>
                      <div className='shoper-test'>
                          <img src={profilePic} alt="user"/>
                          <div className='shoper-list'>
                            <p className='shoper-room'>{location}</p>
                            <p className='shoper-name'>{username}</p>
                          </div>
                    </div>
                </div>
              
                {/* <p className='detatil-grid'>Details <Link to={`/order-detail/${orderId}`}><img src='/img/buildingowner/arrow.png' alt='icon'/></Link></p> */}
                {/* <div onClick={() => handleClick(orderId)}> */}
                <Link to={`/order-detail/${orderId}`}> 
                <p className='detatil-grid'>Details <img src='/img/buildingowner/arrow.png' alt='icon' /></p>
                </Link>
                {/* </div> */}
                </div>

{/*   Order list starts */}
          {orderId && selectedOrder === orderId ? 
                <div className='amt-line'>
                    <div className='amt-abosulte'>

                    { items.map((item)=>{
                      return ( 
                      <div className='amt-felx-main'>
                      <div className='am-div'>
                          <p className='amt-sub-text'>{item.quantity}   <img src='/img/buildingowner/route.png' alt='icon'/></p>
                          <p className='amt-text'>{item.dishName}</p>
                      </div>
                      <p className='amt-fee'>Amount: ฿{item.price}</p>
                      </div>
                      )
                    })
                  }                    
                    {/* <div className='amt-felx-main'>
                        <div className='am-div'>
                            <p className='amt-sub-text'>1   <img src='/img/buildingowner/route.png' alt='icon'/></p>
                            <p className='amt-text'>Amout total: ฿100</p>
                        </div>
                        <p className='amt-fee'>Fee: ฿20</p>
                    </div>
                    <div className='amt-felx-main'>
                        <div className='am-div'>
                            <p className='amt-sub-text'>2 <img src='/img/buildingowner/route.png' alt='icon'/></p>
                            <p className='amt-text'>Amout total: ฿100</p>
                        </div>
                        <p className='amt-fee'>Fee: ฿20</p>
                    </div>
                    <div className='amt-felx-main'>
                        <div className='am-div'>
                            <p className='amt-sub-text'>3 <img src='/img/buildingowner/route.png' alt='icon'/></p>
                            <p className='amt-text'>Amout total: ฿100</p>
                        </div>
                        <p className='amt-fee'>Fee: ฿20</p>
                    </div> */}
                </div>
                </div>
                 :'' }
{/*   Order list ends */}
  
            </div>
          )
          

        })

        //  shoper.map((person)=>{
        //   console.log(person);
        //   let{order,picture,room,name}= person

        //   return(
        //     <div className='wrapper-test'>
        //       <div className='grid-person'>
        //           <div className='shoper-flex-list'>
        //               <p className='filter-item'>{order}<img src='./img/buildingowner/route.png' alt='icon'/></p>
        //               <div className='shoper-test'>
        //                   <img src={picture} alt="user"/>
        //                   <div className='shoper-list'>
        //                     <p className='shoper-room'>{room}</p>
        //                     <p className='shoper-name'>{name}</p>
        //                   </div>
        //             </div>
        //         </div>
              
        //         <p className='detatil-grid'>Details <Link to='/page3'><img src='./img/buildingowner/arrow.png' alt='icon'/></Link></p>
        //         </div>
        //     </div>
        //   )
          
    
        // })

    }
    </section>
 
    </>
  )
}

export default ShoperDetail